export enum CalculationType {
  RetirementBenefit = 0,
  Survivor = 1,
  JointSurvivor = 2,
  Reduced = 3,
  DisabilityShortTerm = 4,
  DisabilityLongTerm = 5,
  Disability = 6,
  Refund = 7,
  LODDSurvivor = 8,
  LODDDeath = 9,
  LODDBenefit = 10,
  ChildSurvivor = 11,
  QDRO = 12,
}

export enum CalculationStepType {
  NonList,
  List,
}

export enum BenefitRequestType {
  UploadCalculationFile,
  InitiateRetirementBenefit,
}

export enum GetParameterMappingType {
  Input,
  Output,
}

export enum CalculationParameterConfigurationType {
  Property,
  Input,
  Output,
}

export enum CalculationParameterType {
  Property,
  Input,
  Output,
  Aggregation,
}

export enum GetListSectionRecordValuesType {
  None,
  ExistingBenefitData,
}

export enum CalculationDocumentType {
  CertificateOfEligibility,
  NoticeOfRetirement,
  MarriedCertificate,
  InvoiceVoucher,
  CalculationWorksheet,
  // disability document types
  ActivityPrescription,
  EmployerNote,
  PayStub,
  MedicalExam,
  PhysicianAnnualLetter,
  TaxStatement,
}

export enum DisplayDocumentTypeName {
  'Certificate of Eligibility',
  'Notice of Retirement',
  'Married Certificate',
  'Invoice Voucher',
  'Calculation worksheet',
  // disability document types
  'Activity Prescription',
  'Employer Note',
  'Pay Stub',
  'Medical Exam',
  'Physician Annual Letter',
  'Tax Statement',
  'Death Certificate',
  'Other',
}

export enum CalculationBenefitHistoryStatus {
  Initiated,
  'Pending Approval',
  Approved,
}

export enum PersonNameField {
  First = 'First',
  Last = 'Last',
  Prefix = 'Prefix',
}

export enum AddressField {
  Street1 = 'Street1',
  Street2 = 'Street2',
  City = 'City',
  State = 'State',
  StateCode = 'StateCode',
  ZipCode = 'ZipCode',
  Country = 'Country',
  CountryCode = 'CountryCode',
}

export enum BenefitCalculationErrorType {
  // Benefit/Calculation Details
  InformationHasBeenChanged = 0,
  NeedToFillInAllInfo = 1,
  Exception = 2,
  CalculationNotInitiated = 3,
  CalculationNotPendingApproval = 4,
  AccidentStatusNotApproved = 6,
  AccidentFatalNotYes = 7,
  NoLongerEligibleForRefund = 8,
  NotFound = 9,
  DeathCertificateIsMissing = 12,
  BenefitEntityMissing = 14,
  PendingBenefitCalculation = 15,
  NoCourtOrderAvailable = 16,
  NonExistingRetirementBenefitCalculation = 17,
  InvalidLongTermDisabilityDate = 19,
  MemberDeceased = 20,
  NoChildAvailableForInitiate = 22,
  CalculationAsOfDateAndBenefitAmountAreNotUpdated = 100,
  IsOpenMigratedPaymentInstruction = 101,
  DiscrepancyMemberTaxExclusion = 102,
  DifferentMemberTaxExclusion = 103,
  SurvivorTaxExclusionConfirmation = 104,
  MissingCalendarConfiguration = 105,

  // Benefit Overview
  CalculationFileIsMissing = 10,
  StepConfigurationIsMissing = 11,
  ExistedBenefitOption = 18,

  // Not used
  OverDueDate = 5,
  PayeeInformationNotFound = 13,
}

export enum ResponseStatus {
  Success = 'Success',
  Error = 'Error',
}

export enum ExceptionType {
  ServicePeriodsNotEnded = 0,
  MemberMissingDOB = 1,
  MemberMinAgeNotMet = 2,
  MemberMinServiceNotMet = 3,
  MemberMinPensionPaymentsNotMet = 4,
  SpouseNotFound = 5,
  MultipleSpousesFound = 6,
  SpouseMissingDOB = 7,
  BenefitEffectiveBeforeServiceTerminated = 8,
  BenefitEffectiveMoreThan6MonthsFuture = 9,
  MemberDeceasedBeforeBenefitEffective = 10,
  EffectiveBasePensionAmountNotFound = 11,
  EffectiveAgeReductionNotFound = 12,
  EffectiveBasePaidAmountNotFound = 13,
  EffectivePensionPaymentAmountNotFound = 14,
  EffectiveMinMonthlyPensionAmountNotFound = 15,
  EffectiveLumpSumSettlementFactorNotFound = 16,
  EffectiveJointSurvivorFactorNotFound = 17,
  SpouseIsDeceased = 18,
  MemberIsNotDeceased = 19,
  DifferentSpouseInRetirement = 20,
  EffectiveLongTermDisabilityMemberAmountNotFound = 21,
  EffectiveLongTermDisabilitySpouseAmountNotFound = 22,
  EffectiveLongTermDisabilityDependentChildAmountNotFound = 23,
  DependentChildMissingDOB = 24,
  AllYearsMustBeRefundedForDeceasedMember = 25,
  PayeeInformationNotFound = 26,
  DateValueValidation = 27,
  MemberIsRetired = 28,
  MemberDeathNotLineOfDuty = 29,
  EffectiveLODDDeathAmountNotFound = 30,
  EffectiveLODDFuneralAmountNotFound = 31,
  PrimaryBeneficiaryNotFound = 32,
  PrimaryBeneficiariesPercentageNotEqual100 = 33,
  EffectiveLODDSurvivorAmountNotFound = 34,
  EffectiveLODDChildAmountFound = 35,
  SurvivorNotFound = 36,
  AssociatedQDROCourtOrderIsPending = 37,
  QDROBenefitIsNotApproved = 38,
  RetirementBenefitIsNotCompleted = 39,
  CalendarIsNotConfigured = 40,
}

export enum ComparisonOperator {
  EqualTo,
  GreaterThan,
  GreaterThanOrEqual,
  LessThan,
  LessThanOrEqual,
}

export enum ComparisonOperatorLabel {
  'Equal to',
  'Greater Than',
  'Greater Than or Equal To',
  'Less Than',
  'Less Than or Equal To',
}

export enum ComparisonOperatorSymbol {
  '=',
  '>',
  '>=',
  '<',
  '<=',
}

export enum ExceptionDataSource {
  'Member Entity',
  'Input Mapping',
  'Output Mapping',
  'Unknown',
}

export enum MonthYearUnit {
  Year = 'Year',
  Month = 'Month',
}

export enum DisabilityType {
  'Short-term' = 0,
  'Long-term' = 1,
}

export enum DependentType {
  Spouse = 0,
  Child = 1,
}

export enum BenefitOptions {
  Single,
  Multiple,
  All,
}

export enum CalculationBenefitStatus {
  Initiated,
  PendingApproved,
  Approved,
}

export enum MasterParameterType {
  Property,
  Input,
  Output,
  Aggregation,
}

export enum GranulationOptionsType {
  None,
  Year,
  YearsMonths,
  YearsMonthsDays,
}

export enum ActionButtonOnCalculationDetail {
  Compute,
  Complete,
  Approve,
}

export enum RetirementBenefitDetailGridDataType {
  Exceptions = 'Exceptions',
  RetirementBenefitDocuments = 'Retirement Benefit Documents',
  CalculationAuditTrails = 'Audit Trails',
}

export enum StepPropertiesType {
  Initiate,
  Recalculate,
  Both,
}

export enum MultipleBankType {
  BenefitSubtype,
  EmployerType,
}
