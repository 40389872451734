import { Injectable } from '@angular/core';

import { DateTime } from 'luxon';

import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { getDateString, isEmpty } from '@ptg-shared/utils/string.util';
import { deepClone, toTimeZoneLocal } from '@ptg-shared/utils/common.util';

import {
  DependentType,
  CalculationType,
  CalculationBenefitHistoryStatus,
  BenefitCalculationErrorType,
} from '../../types/enums';
import {
  DependentInformationItem,
  CalculationDetailDisplayData,
  GetDisabilityCalculationDetailResponse,
  DisabilityBenefit,
  StepperState,
  AuditTrailLine,
} from '../../services/models';

@Injectable()
export class DisabilityCalculationDetailComponentService {
  getBreadcrumbs(
    calculationType: CalculationType,
    memberId: string = '',
    calculationBenefitId: string = '',
  ): Breadcrumb[] {
    return [
      {
        name: 'Overview',
        url: `/member/disability-overview/${calculationType}/${memberId}/detail/${calculationBenefitId}`,
      },
      {
        name: `Disability Calculation Detail`,
      },
    ];
  }

  getErrorMessageByType(errorType?: BenefitCalculationErrorType, actionType: string = 'complete', confirmMessage: string = ''): string {
    switch (errorType) {
      case BenefitCalculationErrorType.CalculationNotInitiated:
        return `Can only ${actionType} calculation record at status "Initiated".`;
      case BenefitCalculationErrorType.CalculationNotPendingApproval:
        return 'Can only approve calculation record at status "Pending approval".';
      case BenefitCalculationErrorType.Exception:
        return 'Please resolve exception to proceed.';
      case BenefitCalculationErrorType.InformationHasBeenChanged:
        return 'Some information has been changed and might affect calculation. Please re-calculate before proceeding.';
      case BenefitCalculationErrorType.NeedToFillInAllInfo:
        return 'Please fill in all information to proceed.';
      case BenefitCalculationErrorType.IsOpenMigratedPaymentInstruction:
      case BenefitCalculationErrorType.MissingCalendarConfiguration:
        return confirmMessage;
      default:
        return 'Error occurred. Please try again.';
    }
  }

  getStepperState(auditTrailLines: AuditTrailLine[] = []): StepperState | null {
    if (!auditTrailLines.length) {
      return null;
    }

    const optionalLabels: string[] = deepClone(auditTrailLines)
      .sort((a: any, b: any) => a.step - b.step)
      .map((item: any) => {
        const completeTimeToLocal = item.submittedDate
          ? toTimeZoneLocal(new Date(item.submittedDate)).toISOString()
          : '';
        const completeTime = DateTime.fromISO(completeTimeToLocal).toFormat('MM/dd/yyyy hh:mm a');
        const label = item.submittedBy ? `${item.submittedBy} - ${completeTime}` : '';

        return label;
      });

    const currentStep = auditTrailLines.filter((itemAudit) => itemAudit.submittedDate !== null).length || 0;

    const stepperState = {
      selectedIndex: auditTrailLines.length - 1 || 0,
      currentStep,
      labels: ['Calculate', 'Approve'],
      optionalLabels,
    };
    const completedStep = auditTrailLines.length;

    return {
      stepperState,
      completedStep,
    };
  }

  handleDataDisplay(
    detailData: GetDisabilityCalculationDetailResponse,
    limitReasonLength = 390,
  ): CalculationDetailDisplayData | null {
    if (detailData.benefitCalculation === undefined) {
      return null;
    }

    const calculationType = detailData.calculationType;
    const isLongTerm = detailData.calculationType === CalculationType.DisabilityLongTerm;
    const isShowEditBtn = detailData.benefitCalculation?.status === CalculationBenefitHistoryStatus.Initiated;
    const recalReason = {
      isTruncate: true,
      note: detailData.benefitCalculation?.recalReason ?? '',
    };

    const itemDetail = detailData.benefitCalculation;
    const calcEndDate = itemDetail.benefitEndDate;
    const calcAsOfDate = itemDetail.calcAsOfDate;
    const childNotDeceasedNumber: number = detailData.dependentInformationList.filter(
      (dependItem: DependentInformationItem) => dependItem.type === DependentType.Child && (dependItem.dateOfDeath === null || dependItem.dateOfDeath >= calcAsOfDate),
    )?.length;
    const spouseNotDeceasedNumber = detailData.dependentInformationList.filter(
      (dependItem: DependentInformationItem) => dependItem.type === DependentType.Spouse && (dependItem.dateOfDeath === null || dependItem.dateOfDeath >= calcAsOfDate)
    )?.length;
    const benefitCalculationData = {
      ...detailData.benefitCalculation,
      memberAmount: this.handleAmountValue(+detailData.benefitCalculation?.memberAmount),
      spouseAmount: +detailData.benefitCalculation?.spouseAmount < 0 || spouseNotDeceasedNumber === 0 ? '' : +this.handleAmountValue(+detailData.benefitCalculation?.spouseAmount) * spouseNotDeceasedNumber,
      childAmount: this.handleAmountValue(+detailData.benefitCalculation?.childAmount),
      childNumber: childNotDeceasedNumber,
      totals: this.calculateTotalsBenefit(itemDetail, isLongTerm, childNotDeceasedNumber, spouseNotDeceasedNumber),
    };

    const isShowSeeMore = detailData.benefitCalculation?.recalReason?.length > limitReasonLength;
    const canRecalculate = itemDetail.status === CalculationBenefitHistoryStatus.Approved;
    const isShowReason =
      detailData.numberOfCalculationRecordsInBenefit > 1 &&
      !isEmpty(itemDetail.recalReason) &&
      itemDetail.recalReason != '';

    const dependentsInfor = deepClone(
      detailData.dependentInformationList?.map((item: any) => {
        return {
          ...item,
          relationship: DependentType[item.type],
          ssn: this.renderSSNDisplay(item.ssn),
          dateOfBirth: getDateString(item.dateOfBirth),
          dateOfDeath: getDateString(item.dateOfDeath),
        };
      }) ?? [],
    );

    return {
      calculationType,
      isLongTerm,
      isShowEditBtn,
      isShowSeeMore,
      isShowReason,
      canRecalculate,
      calcAsOfDate,
      calcEndDate,
      recalReason,
      dependentsInfor,
      benefitCalculationData,
    };
  }

  private renderSSNDisplay(input: string) {
    if (isEmpty(input) || input === '') {
      return '-';
    }
    return `XXX-XX-${input.slice(-4)}`;
  }

  private handleAmountValue(input: number): number | string {
    if (!input || input == -1) {
      return '';
    }
    return input;
  }

  private calculateTotalsBenefit(item: DisabilityBenefit, isLongTerm: boolean, childNumber = 0, spouseNumber = 0): number {
    if (!isLongTerm) {
      return 0;
    }
    if (+item.memberAmount < 0 && +item.spouseAmount < 0 && +item.childAmount < 0) {
      return -1;
    } else {
      const memberAmount = this.handleAmountValue(+item.memberAmount) ?? 0;
      const spouseAmount = +this.handleAmountValue(+item.spouseAmount)*spouseNumber ?? 0;
      const childAmount = this.handleAmountValue(+item.childAmount) ?? 0;
      return +memberAmount + +spouseAmount + +childAmount * childNumber;
    }
  }
}
