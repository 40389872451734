<div class="grid-container flex flex-col" [ngClass]="{ 'fit-to-parent': fitToParent }">
  <div class="table-container" [ngClass]="{ 'hide-scrollbar': hideScrollbar }">
    <table
      *ngIf="!!columns?.length"
      mat-table
      [id]="id"
      [dataSource]="tableDataSource"
      [cdkDropListData]="tableDataSource.data"
      cdkDropList
      [cdkDropListConnectedTo]="connectedTo"
      [cdkDropListDisabled]="!allowDragDrop"
      [cdkDropListSortPredicate]="customPredicate"
      (cdkDropListDropped)="onDropRow($event)"
      matSort
      [matSortActive]="matSortActive!"
      [matSortDirection]="matSortDirection!"
      (matSortChange)="onChangeSort($event)"
    >
      <!-- BEGIN Generate the drag drop column -->
      <ng-container *ngIf="allowDragDrop" [matColumnDef]="DRAG_DROP_COLUMN">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button
            aria-label="drag rows"
            stopPropagation
            type="button"
            class="drag-button"
            [ngClass]="{ 'drag-button-disable': row.disableDragDrop }"
            [disabled]="row.disableDragDrop"
            (mousedown)="onDragRow()"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>drag_handle</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- END -->

      <!-- BEGIN Generate the selection column -->
      <ng-container *ngIf="allowSelection" [matColumnDef]="SELECTION_COLUMN">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [aria-label]="'ARIA Label for row selection checkBox'"
            *ngIf="!hideHeaderSelection"
            [disabled]="disableAllCheckbox || disableSelection"
            [checked]="selection.hasValue() && isAllChecked()"
            [indeterminate]="selection.hasValue() && isIndeterminate()"
            (change)="masterToggle()"
          >
            <span style="display: none">Row selection checkBox</span>
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row; let idx = index">
          <ng-container *ngIf="row.hideCheckBox else showSelection">
            <!-- Binding the template from outside (if any) -->
            <ng-container
              [ngTemplateOutlet]="getCellContentTemplate(SELECTION_COLUMN) || defaultTemplate"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-container>

            <!-- Transform display data with "cell" function (if any) -->
            <ng-template #defaultTemplate></ng-template>
          </ng-container>
          <ng-template #showSelection>
            <mat-checkbox
              [aria-label]="'ARIA Label for row selection checkBox'"
              stopPropagation
              *ngIf="!row.hideCheckBox"
              [checked]="isChecked(row, idx)"
              [disabled]="disableAllCheckbox || row.isDisabledCheckBox"
              [indeterminate]="isGroup?.(idx, row) && row.isIndeterminateGroup"
              (change)="$event.checked ? check(row, true, idx) : uncheck(row, true, idx)"
              (click)="$event.stopPropagation()"
            ></mat-checkbox>
          </ng-template>
        </td>
      </ng-container>
      <!-- END -->

      <!-- BEGIN common display mode -->
      <ng-template #commonDisplayMode let-col="col" let-row>
        <ng-container [ngSwitch]="col.type">
          <!-- Binding the common template by column type -->
          <ptg-number-decimal-column
            *ngSwitchCase="ColumnType.Decimal"
            [value]="row[col.name]"
            [decimal]="col.templateArgs?.decimal || col.templateArgs?.decimal === 0 ? col.templateArgs?.decimal : 2"
            [unit]="col.templateArgs?.unit"
            [unitPosition]="col.templateArgs?.unitPosition || 'left'"
            [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay"
            [accountingFormat]="!!col.templateArgs?.accountingFormat"
          ></ptg-number-decimal-column>
          <ptg-datetime-column
            *ngSwitchCase="ColumnType.DateTime"
            [value]="row[col.name]"
            [format]="col.templateArgs?.format || 'MM/dd/yyyy'"
            [emptyString]="col.templateArgs?.emptyString"
            [keepLocalTime]="col.templateArgs?.keepLocalTime"
          ></ptg-datetime-column>

          <ptg-view-rich-text
            *ngSwitchCase="ColumnType.RichText"
            [title]="col.header?.title"
            [content]="row[col.name]"
          ></ptg-view-rich-text>

          <ptg-phone-column
            *ngSwitchCase="ColumnType.PhoneNumber"
            [value]="row[col.name]"
            [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay || ''"
          ></ptg-phone-column>

          <ptg-icon-column
            *ngSwitchCase="ColumnType.Icon"
            [name]="row[col.templateArgs?.nameField]"
            [color]="row[col.templateArgs?.colorField]"
            [label]="col.templateArgs?.labelField ? row[col.templateArgs?.labelField] : ''"
            [style]="col.templateArgs?.styleField"
          ></ptg-icon-column>

          <ptg-address-column
            *ngSwitchCase="ColumnType.Address"
            [value]="row[col.name]"
            [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay || ''"
          ></ptg-address-column>
          <ptg-entity-reference-column
            *ngSwitchCase="ColumnType.EntityReference"
            [disableLink]="col?.name && row[col?.name + '_isDisableLink'] ? true : false"
            [text]="
              entityReferenceNameIsString(row[col.name]) ? row[col.name] : (row[col.name] | personName: row?.options)
            "
            [href]="col.templateArgs?.href ? row[col.templateArgs?.href] : ''"
            (clickEntityReference)="col.templateArgs?.onClick ? col.templateArgs?.onClick(row) : null"
          ></ptg-entity-reference-column>

          <ptg-hyperlink-column
            *ngSwitchCase="ColumnType.Link"
            [text]="row[col.name]"
            [href]="col.templateArgs?.href ? row[col.templateArgs?.href] : ''"
            (clickLink)="col.templateArgs?.onClick ? col.templateArgs?.onClick(row) : null"
          ></ptg-hyperlink-column>

          <ptg-person-name-column
            *ngSwitchCase="ColumnType.PersonName"
            [value]="row[col.name]"
            [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay || ''"
          ></ptg-person-name-column>

          <ptg-binary-column
            *ngSwitchCase="ColumnType.Binary"
            [value]="row[col.name]"
            [templateArgs]="col.templateArgs"
          ></ptg-binary-column>

          <ng-container *ngSwitchDefault>
            <!-- Binding the template from outside (if any) -->
            <ng-container
              [ngTemplateOutlet]="getCellContentTemplate(col.name) || defaultTemplate"
              [ngTemplateOutletContext]="{ $implicit: row, col: col }"
            ></ng-container>

            <!-- Transform display data with "cell" function (if any) -->
            <ng-template #defaultTemplate>
              {{ col.cell ? col.cell(row) : row[col.name] ? row[col.name] : col.templateArgs?.emptyString }}
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-template>
      <!-- END common display mode -->

      <!-- BEGIN table group -->
      <ng-container *ngIf="groupColumns">
        <ng-container *ngFor="let col of groupColumns; let index" [matColumnDef]="col.name">
          <td
            [attr.colspan]="getGroupColspan?.(col, columns)"
            mat-cell
            *matCellDef="let row"
            [class]="getColumnClasses(col)"
            [ngStyle]="getCellStyle(col)"
          >
            <ng-container
              [ngTemplateOutlet]="commonDisplayMode"
              [ngTemplateOutletContext]="{ $implicit: row, col: col }"
            ></ng-container>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: groupDisplayedColumns; when: isGroup" [class]="groupRowClasses"></tr>
      </ng-container>
      <!-- END table group -->

      <!-- BEGIN Generate the columns -->
      <ng-container *ngFor="let col of columns" [matColumnDef]="col.name">
        <th
          mat-header-cell
          *matHeaderCellDef
          [class]="getColumnClasses(col)"
          [ngStyle]="getHeaderStyle(col)"
          mat-sort-header
          [disabled]="!col.sortable"
        >
          {{ col.header?.title || '' }}
        </th>
        <td mat-cell *matCellDef="let row" [class]="getColumnClasses(col)" [ngStyle]="getCellStyle(col)">
          <!-- Editing mode -->
          <ng-container *ngIf="row.editing && col.editable; else displayMode">
            <!-- Binding the inline edit template from outside (if any) -->
            <ng-container
              [ngTemplateOutlet]="getCellEditTemplate(col.name) || defaultInlineEditTemplate"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-container>

            <!-- Binding the default inline edit template if not passing from outside -->
            <ng-template #defaultInlineEditTemplate>
              <ptg-textbox
                *ngIf="!col.controlType && col.controlType !== ControlType.Select"
                stopPropagation
                [placeholder]="
                  col.controlArgs?.placeholder || col.header?.title || row[col.name + 'Placeholder'] || col.name
                "
                [showError]="false"
                [controlField]="row.form?.controls[col.name]"
                (valueChange)="row[col.name] = allowSaveInline ? row[col.name] : $event"
                [maxLength]="col.controlArgs?.maxLength"
              ></ptg-textbox>
              <ptg-select
                *ngIf="col.controlType === ControlType.Select"
                stopPropagation
                [controlField]="row.form?.controls[col.name]"
                [listData]="col.controlArgs?.listOptions ? col.controlArgs?.listOptions : []"
                (valueChange)="row[col.name] = allowSaveInline ? row[col.name] : $event"
              ></ptg-select>
              <ptg-textbox
                *ngIf="col.controlType === ControlType.Number"
                stopPropagation
                [placeholder]="col.controlArgs?.placeholder || col.header?.title || col.name"
                [showError]="false"
                [controlField]="row.form?.controls[col.name]"
                inputType="Number"
                [isDecimal]="false"
                (valueChange)="row[col.name] = allowSaveInline ? row[col.name] : $event"
              ></ptg-textbox>

              <ptg-textbox
                *ngIf="col.controlType === ControlType.Currency"
                stopPropagation
                [placeholder]="col.controlArgs?.placeholder || col.header?.title || col.name"
                inputType="Currency"
                [controlField]="row.form?.controls[col.name]"
                (valueChange)="row[col.name] = allowSaveInline ? row[col.name] : $event"
              ></ptg-textbox>

              <ptg-checkbox
                *ngIf="col.controlType === ControlType.Checkbox && row.form?.controls[col.name]"
                [controlField]="row.form?.controls[col.name]"
                label=""
              ></ptg-checkbox>
              <mat-error *ngIf="row.form?.controls[col.name]?.errors" style="font-size: 14px; text-align: left">
                {{ getValidationMsg(row, col) }}
              </mat-error>
            </ng-template>
          </ng-container>
          <ng-template #displayMode>
            <ng-container
              [ngTemplateOutlet]="commonDisplayMode"
              [ngTemplateOutletContext]="{ $implicit: row, col: col }"
            ></ng-container>
          </ng-template>

          <!-- BEGIN Generate the action column with extra actions -->
          <ng-container *ngIf="col.name === ACTION_COLUMN && !row.deleted">
            <!-- Inline Edit button -->
            <ng-container *ngIf="inlineEditable && !row.nonEditable">
              <ng-container *ngIf="row.editing; else notEditing">
                <ptg-button stopPropagation classInput="yesno-button" (clickButton)="onClickCancelInlineEdit(row)">
                  <mat-icon>close</mat-icon>
                </ptg-button>
                <ptg-button
                  *ngIf="allowSaveInline"
                  stopPropagation
                  classInput="yesno-button"
                  (clickButton)="onClickSaveInlineEdit(row)"
                >
                  <mat-icon style="color: #196f57">check</mat-icon>
                </ptg-button>
              </ng-container>

              <ng-template #notEditing>
                <ptg-button stopPropagation classInput="edit-button" (clickButton)="onClickInlineEdit(row)">
                  <mat-icon>edit</mat-icon>
                </ptg-button>
              </ng-template>
            </ng-container>

            <!-- Soft delete button -->
            <ng-container *ngIf="softDeletable && !row.isStatic && !row.editing">
              <ptg-button stopPropagation classInput="delete-button" (clickButton)="onClickSoftDetele(row)">
                <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </ng-container>
          </ng-container>
          <!-- END -->
        </td>
      </ng-container>

      <!-- Note: Define a ghost column to re-render the table in case "columns" change but "displayColumns" does not change -->
      <ng-container [matColumnDef]="GHOST_COLUMN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="true"></th>
        <td mat-cell *matCellDef="let row" [hidden]="true"></td>
      </ng-container>
      <!-- END -->

      <!-- BEGIN Generate the rows -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: fixedHeader"
        [ngClass]="{ 'hide-header': hideHeader }"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; let idx = index; columns: displayedColumns"
        [class]="getRowClasses(row)"
        [class.selected-row]="idx === currentRowIndex && rowClick.observers.length > 0"
        [style.background-color]="row.backgroundColor"
        [class.payment-highlight]="row.isHighlight"
        cdkDrag
        cdkDragLockAxis="y"
        [cdkDragData]="row"
        [cdkDragDisabled]="isDragDisabled"
        (click)="row.disabledRow ? $event.stopPropagation() : rowClick.observers.length > 0 ? onClickRow(row, idx, $event) : null"
      ></tr>
      <!-- END -->
    </table>
  </div>

  <div class="flex-grow flex">
    <div *ngIf="isLoading; else notLoading" class="spinner m-auto">
      <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
      <p>{{ loadingMessage }}</p>
    </div>
    <ng-template #notLoading>
      <div *ngIf="!data?.length && notFoundMessage" class="not-found m-auto flex flex-col">
        <ng-container *ngIf="errorMessage || fullErrorMessage; else notError">
          <div class="warning-icon">
            <mat-icon class="material-icons-round">warning</mat-icon>
          </div>
          <div>
            <span *ngIf="fullErrorMessage; else errorMessageTemplate">{{ fullErrorMessage }}</span>
            <ng-template #errorMessageTemplate>
              <span class="error-msg">{{ errorMessage }} error</span>
              <span>occurred, please try again later.</span>
            </ng-template>
          </div>
        </ng-container>

        <ng-template #notError>
          {{ notFoundMessage }}
        </ng-template>
      </div>
    </ng-template>
  </div>

  <ptg-pagination
    *ngIf="paginable"
    #paginator
    [totalRecords]="totalRecords"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    [maxPages]="maxPages"
    [hiddenPageSizeOptions]="hiddenPageSizeOptions"
    (pageChange)="onChangePage($event)"
  ></ptg-pagination>
</div>
