import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { MunicipalityTypeComponent } from '@ptg-member/features/calculation/components/municipality-type/municipality-type.component';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { OverviewHeaderComponent } from '@ptg-member/components/overview-header/overview-header.component';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import {
  approveDisabilityBenefitAction,
  approveDisabilityBenefitSelector,
  CalculationState,
  checkExceptionConfigurationAction,
  checkExceptionSelector,
  checkRetirementDocumentCanRemoveAction,
  clearApproveDisabilityBenefitStateAction,
  clearCheckExceptionConfigurationStateAction,
  clearCheckRetirementDocumentCanRemoveStateAction,
  clearCompleteDisabilityBenefitStateAction,
  clearComputeDisabilityBenefitStateAction,
  clearEditDisabilityBenefitStateAction,
  clearGetExceptionListStateAction,
  clearGetRetirementBenefitDetailDocumentsStateAction,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  clearRemoveRemoveCalculationDocumentDetailStateAction,
  clearReopenCalculationBenefitStateAction,
  clearValidateBeforeComputingAction,
  clearValidateDisabilityBenefitStateAction,
  completeDisabilityBenefitAction,
  completeDisabilityBenefitSelector,
  computeDisabilityBenefitAction,
  computeDisabilityBenefitSelector,
  getDisabilityCalculationDetailAction,
  getDisabilityCalculationDetailSelector,
  getExceptionListAction,
  getExceptionListSelector,
  getRetirementBenefitDetailDocumentsAction,
  getRetirementBenefitDetailDocumentsSelector,
  getRetirementBenefitDownloadDocumentSelector,
  removeRemoveCalculationDocumentDetailAction,
  removeRemoveCalculationDocumentDetailSelector,
  reopenCalculationBenefitAction,
  reopenCalculationBenefitSelector,
  validateBeforeComputingAction,
  validateBeforeComputingSelector,
  validateDisabilityBenefitAction,
  validateDisabilityBenefitSelector,
  getCalculationAuditTrailAction,
  getCalculationAuditTrailSelector,
  clearGetCalculationAuditTrailStateAction,
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDocumentSelector,
  createRetirementBenefitDetailUploadDocumentAction,
  createRetirementBenefitDetailUploadDocumentSelector,
  clearCreateRetirementBenefitDetailUploadDocumentStateAction,
  clearEditRetirementBenefitDocumentStateAction,
  editRetirementBenefitDocumentAction,
  clearDisabilityCalculationDetailStateAction,
  clearDisabilityCalculationDetailAllStateAction,
} from '../../store';

import { ACTION, BUTTON_LABEL_CLOSE, DEFAULT_PAGE_SIZE, SORT_TYPE, STATE } from '@ptg-shared/constance';
import { Column } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb, StepperState } from '@ptg-shared/types/models/breadcrumb.model';
import {
  ActionButtonOnCalculationDetail,
  CalculationBenefitHistoryStatus,
  CalculationType,
  DependentType,
  RetirementBenefitDetailGridDataType,
  BenefitCalculationErrorType,
} from '../../types/enums';

import { MenuItemSubTitle, MenuItemTitle } from '@ptg-member/constants';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { deepClone, downloadFile, showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { AddDisabilityComponent } from '../../components';
import {
  CheckComputeDisabilityDataResponse,
  CheckExceptionRequest,
  ExceptionListItem,
  GetDisabilityBenefitDetailRequest,
  GetExceptionRequest,
  RetirementBenefitDetailDocument,
  CalculationAuditTrail,
  GetCalculationAuditTrailRequest,
  RetirementBenefitDocument,
  CreateRetirementBenefitDetailUploadDocumentRequest,
  GetDisabilityCalculationDetailResponse,
  DependentInformationItem,
  GetListEmployerTypesRequest,
} from '../../services/models';
import {
  BenefitType,
  DISABILITY_DOCUMENT_TYPE_OPTIONS,
  DisabilityTableType,
  UPLOAD_DOCUMENT_RADIO_LIST,
} from '../../constants';
import { checkExistCheckRetirementDocumentCanRemoveSelector } from '../../store/selectors/check-retirement-document-can-remove.selector';
import {
  GRID_COLUMN_EXCEPTION_LIST,
  GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT,
  GRID_COLUMN_CALCULATION_AUDIT_TRAILS,
} from '../../benefit-detail.constants';
import { BenefitDetailComponentService } from '../../services';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { DOCUMENT_LOCATION, USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';
import { editRetirementBenefitDocumentsSelector } from '../../store/selectors/retirement-benefit-upload-document.selector';
import { DisabilityCalculationDetailComponentService } from './disability-calculation-detail.component.service';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import * as fromReducer from '@ptg-reducers';

const PAGE_SIZE_CONST = '-ptg-disability-calculation-detail-pageSize';

@Component({
  selector: 'ptg-disability-calculation-detail',
  templateUrl: './disability-calculation-detail.component.html',
  styleUrls: ['./disability-calculation-detail.component.scss'],
  providers: [DisabilityCalculationDetailComponentService],
  encapsulation: ViewEncapsulation.None,
})
export class DisabilityCalculationDetailComponent extends BaseListComponent {
  readonly CalculationBenefitHistoryStatus = CalculationBenefitHistoryStatus;
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;
  @ViewChild('municipalityType') municipalityType!: MunicipalityTypeComponent;

  menuItemTitle: string = MenuItemTitle.Disability;
  menuItemSubTitle: string = MenuItemSubTitle.BenefitsProcessing;

  currentFund: any = {};
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  PAGE_KEY = '-ptg-disability-calculation-detail';
  memberId = '';

  listBreadcrumbs: Breadcrumb[] = [];
  stepperState?: StepperState;
  completedStep = 0;

  private calculationRecordId = '';
  private calculationBenefitId = '';
  calculationType: CalculationType = CalculationType.DisabilityShortTerm;

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  sortInfo?: Sort;

  isShowEditBtn: boolean = false;
  isLoading?: boolean = false;
  isLongTerm: boolean = false;
  isShowReason: boolean = false;
  isReadMore: boolean = false;
  isShowSeeMore: boolean = false;
  detailData?: GetDisabilityCalculationDetailResponse;

  totalExceptionRecords = 0;
  isExceptionLoading = true;
  pageExceptionIndex = FIRST_PAGE;
  pageExceptionSize = 3;
  pageExceptionSizeOptions = [1, 3, 5, 10, 15, 20];
  exceptionList: ExceptionListItem[] = [];
  columnException: Column[] = GRID_COLUMN_EXCEPTION_LIST;
  private sortInfoException: Sort = { active: 'exceptionName', direction: 'asc' };

  private passedExceptionTrigger = new Subject<boolean>();

  downloadFileName: string = 'sample.pdf';
  removedDocumentId: string = '';

  totalBenefitDocuments = 0;
  isDocumentLoading = true;
  pageDocumentIndex: number = FIRST_PAGE;
  pageDocumentSize: number = DEFAULT_PAGE_SIZE;
  disabilityBenefitDocuments: RetirementBenefitDetailDocument[] = [];
  columnsBenefitDocuments: Column[] = GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT;

  totalCalculationAuditTrailRecords = 0;
  isCalculationAuditTrailLoading = true;
  pageCalculationAuditTrailIndex = FIRST_PAGE;
  pageCalculationAuditTrailSize = DEFAULT_PAGE_SIZE;
  calculationAuditTrails: CalculationAuditTrail[] = [];
  columnCalculationAuditTrail: Column[] = GRID_COLUMN_CALCULATION_AUDIT_TRAILS;
  private sortInfoCalculationAuditTrail: Sort = { active: 'createdDate', direction: 'desc' };

  calcAsOfDate = '';
  calcEndDate = '';

  recalReason = {
    isTruncate: true,
    note: '',
  };
  benefitCalculationData: any;
  dependentsInfor: (DependentInformationItem & {
    relationship: DependentType;
  })[] = [];
  canRecalculate: boolean = false;

  readonly limitReasonLength = 390;

  readonly existDocumentOptionList: RadioOption[] = UPLOAD_DOCUMENT_RADIO_LIST;

  benefitDocuments: RetirementBenefitDocument[] = [];

  getEmployerTypesRequest?: GetListEmployerTypesRequest;

  constructor(
    private readonly dialog: MatDialog,
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,
    private readonly calculationStore: Store<CalculationState>,
    private readonly retirementBenefitDetailService: BenefitDetailComponentService,
    private readonly retirementBenefitDialogService: RetirementBenefitDialogService,
    private readonly disabilityCalculationDetailComponentService: DisabilityCalculationDetailComponentService,
    private readonly documentStore: Store<DocumentsState>,
    private store: Store<AccidentParticipantsState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.clearStates();

    this.getCurrentFundAndRouteData();

    this.selectCalculationDetailData();

    this.registerGetDisabilityCalculationDetailDocumentData();

    this.selectCalculationAuditTrailState();

    this.selectReopenDisabilityBenefitState();
    this.selectComputeDisabilityBenefitState();
    this.selectCompleteDisabilityBenefitState();
    this.selectApproveDisabilityBenefitState();

    this.selectExceptionListState();
    this.selectCheckExceptionState();

    this.selectorCheckDocumentCanRemove();
    this.selectorRemoveDocument();
    this.selectorDownloadFile();

    this.getDisabilityBenefitDetailDocumentData();
    this.registerGetDisabilityBenefitDetailDocumentData();

    this.uploadDocumentSelector();
    this.registerEditDocumentSelector();
  }

  private setPageSize(): void {
    const auditTrailPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityAuditTrails + PAGE_SIZE_CONST,
      ),
    );

    const benefitDocumentPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityDocuments + PAGE_SIZE_CONST,
      ),
    );

    this.pageCalculationAuditTrailSize =
      auditTrailPageSizeInSession === 0 ? this.defaultPageSize : auditTrailPageSizeInSession;

    this.pageDocumentSize =
      benefitDocumentPageSizeInSession === 0 ? this.defaultPageSize : benefitDocumentPageSizeInSession;
  }

  private getCurrentFundAndRouteData(): void {
    combineLatest([this.route.params, this.store.select(fromReducer.selectCurrentFundState)])
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund]) => {
        const { memberId, calculationBenefitId, calculationType, calculationRecordId } = params;

        this.memberId = memberId;
        this.calculationBenefitId = calculationBenefitId;
        this.calculationType = parseInt(calculationType);
        this.calculationRecordId = calculationRecordId;

        this.listBreadcrumbs = this.disabilityCalculationDetailComponentService.getBreadcrumbs(
          calculationType,
          memberId,
          calculationBenefitId,
        );

        this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
        this.currentFund = currentFund;
        this.setPageSize();

        this.getCalculationDetailData();
        this.getEmployerTypesRequest = {
          memberId,
          calculationBenefitId,
          calculationType,
          params: { calculationRecordId },
        };
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.passedExceptionTrigger.complete();
    this.clearStates();
  }

  private clearStates(): void {
    this.calculationStore.dispatch(clearDisabilityCalculationDetailAllStateAction());
    this.calculationStore.dispatch(clearEditDisabilityBenefitStateAction());
  }

  private getCalculationDetailData(): void {
    const request = {
      calculationBenefitId: this.calculationBenefitId,
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationRecordId: this.calculationRecordId,
    };
    this.calculationStore.dispatch(getDisabilityCalculationDetailAction(request));
  }

  private selectCalculationDetailData(): void {
    this.calculationStore
      .pipe(
        select(getDisabilityCalculationDetailSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearDisabilityCalculationDetailStateAction());

        if (res?.success && res?.payload) {
          this.detailData = res.payload;

          const displayData = this.disabilityCalculationDetailComponentService.handleDataDisplay(
            res.payload,
            this.limitReasonLength,
          );
          if (displayData) {
            const {
              calculationType,
              isLongTerm,
              isShowEditBtn,
              isShowSeeMore,
              isShowReason,
              canRecalculate,
              calcAsOfDate,
              calcEndDate,
              recalReason,
              dependentsInfor,
              benefitCalculationData,
            } = displayData;
            this.calculationType = calculationType;
            this.isLongTerm = isLongTerm;
            this.isShowEditBtn = isShowEditBtn;
            this.isShowSeeMore = isShowSeeMore;
            this.isShowReason = isShowReason;
            this.canRecalculate = canRecalculate;
            this.calcAsOfDate = calcAsOfDate;
            this.calcEndDate = calcEndDate;
            this.recalReason = recalReason;
            this.dependentsInfor = dependentsInfor?.map((item) => ({
              ...item,
              dateOfBirth: getDateString(item?.dateOfBirth),
              dateOfDeath: getDateString(item?.dateOfDeath),
            }));
            this.benefitCalculationData = benefitCalculationData;
          }

          const initStepperState = this.disabilityCalculationDetailComponentService.getStepperState(
            this.detailData?.auditTrailLines,
          );
          if (initStepperState) {
            const { stepperState, completedStep } = initStepperState;
            this.stepperState = stepperState;
            this.completedStep = completedStep;
          }

          this.getExceptionListData();
          this.getDisabilityCalculationDetailDocumentData();
          this.getCalculationAuditTrailData();
        }
      });
  }

  private getDetailDocumentDataRequest(): GetDisabilityBenefitDetailRequest {
    let sortNames;
    let sortType;

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    const requestDetail: GetDisabilityBenefitDetailRequest = {
      pageNumber: this.pageDocumentIndex,
      pageSize: this.pageDocumentSize,
      sortNames,
      sortType,
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationBenefitId: this.calculationBenefitId,
    };

    return requestDetail;
  }

  private handleValidateDataChange(processToStatus: CalculationBenefitHistoryStatus, callback: () => void): void {
    const { employerId } = this.municipalityType?.employerTypeControl?.value ?? {};
    const requestInfo = {
      request: {
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
        processToStatus,
        employerTypeId: employerId
      },
    };
    this.calculationStore.dispatch(validateDisabilityBenefitAction(requestInfo));
    this.calculationStore
      .select(validateDisabilityBenefitSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearValidateDisabilityBenefitStateAction());

        const isValidToProcess = res?.payload?.isValid;
        const isSuccess = res?.success;

        if (isSuccess && isValidToProcess && callback) {
          callback();
          return;
        }

        if (
          processToStatus === CalculationBenefitHistoryStatus.Approved &&
          isSuccess &&
          !isValidToProcess &&
          res?.payload?.errorType ===
            BenefitCalculationErrorType.CalculationAsOfDateAndBenefitAmountAreNotUpdated
        ) {
          const content =
            "New payment instructions will NOT be generated due to the Calculation as of Date and Benefit Amount aren't updated. Are you sure you want to proceed?";
          this.handleConfirmPopupOnApproval('Confirmation', content, ConfirmType.Cancel, callback);
          return;
        }

        if (
          processToStatus === CalculationBenefitHistoryStatus.Approved &&
          isSuccess &&
          (res?.payload?.errorType === BenefitCalculationErrorType.IsOpenMigratedPaymentInstruction || res?.payload?.errorType === BenefitCalculationErrorType.MissingCalendarConfiguration)
        ) {
          this.handleErrorData(res?.payload)
          return;
        }

        if (!isValidToProcess) {
          this.handleErrorData(res?.payload, 'complete');
        }
      });
  }

  private handleErrorData(payload?: CheckComputeDisabilityDataResponse, actionType: string = 'complete'): void {
    const text = this.disabilityCalculationDetailComponentService.getErrorMessageByType(payload?.errorType, actionType, payload?.confirmMessage);

    const confirmResult = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text,
        title: BannerType.Warning,
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
    confirmResult
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getCalculationDetailData();
      });
  }

  private handleConfirmPopupOnApproval(title: string, text: string, type: ConfirmType, callback: () => void) {
    const confirmResult = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      disableClose: true,
      data: {
        text,
        title,
        type,
      },
    });
    confirmResult
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result && callback) {
          callback();
        }
      });
  }

  onClickReopen(): void {
    this.calculationStore.dispatch(
      reopenCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );
  }

  private selectReopenDisabilityBenefitState(): void {
    this.calculationStore
      .select(reopenCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearReopenCalculationBenefitStateAction());

        const isSuccess = res?.success;
        const customMessage = isSuccess
          ? 'Calculation successfully re-opened.'
          : 'Error occurred re-opening Calculation. Please try again.';

        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'Calculation', ACTION.REOPEN, {
          customMessage,
        });

        if (isSuccess) {
          this.getCalculationDetailData();
        }
      });
  }

  onClickCompute(): void {
    this.handleValidateDataCompute(() => {
      this.handleComputeAction();
    });

    this.passedExceptionTrigger
      .pipe(
        filter((exceptionsOccur) => typeof exceptionsOccur === 'boolean'),
        take(1),
      )
      .subscribe(() => {
        this.getCalculationDetailData();
      });
  }

  private handleValidateDataCompute(callback: () => void): void {
    this.calculationStore.dispatch(
      validateBeforeComputingAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );

    this.calculationStore
      .select(validateBeforeComputingSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        take(1),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearValidateBeforeComputingAction());

        if (res?.success && res?.payload?.isValid) {
          if (callback) {
            callback();
          }
        }
        if (!res?.payload?.isValid) {
          this.handleErrorData(res?.payload, 'compute');
        }
      });
  }

  private getDisabilityCalculationDetailDocumentData(): void {
    const requestDocument = this.getDetailDocumentDataRequest();

    this.calculationStore.dispatch(
      getRetirementBenefitDetailDocumentsAction({
        request: {
          ...requestDocument,
          memberId: this.memberId,
          calculationType: this.calculationType,
          benefitTypeId: this.calculationBenefitId,
          calculationId: this.calculationRecordId ?? '',
        },
      }),
    );
  }

  private registerGetDisabilityCalculationDetailDocumentData(): void {
    this.calculationStore
      .select(getRetirementBenefitDetailDocumentsSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isDocumentLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearGetRetirementBenefitDetailDocumentsStateAction());

        if (res?.success) {
          const documents = res?.payload ?? [];
          this.disabilityBenefitDocuments = documents.map((document) => {
            return {
              ...document,
              fileName: document?.fileName,
              uploadDate: document.uploadDate ? getDateString(document.uploadDate) : '',
            };
          });
          this.totalBenefitDocuments = res?.total ?? 0;
        }
      });
  }

  onChangeSortDocuments(event: Sort): void {
    if (event.active === 'documentType') {
      this.sortInfo = {
        active: 'documentTypeName',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getDisabilityCalculationDetailDocumentData();
  }

  onChangeDocumentsPage(event: PageEvent): void {
    this.pageDocumentSize = event.pageSize;
    this.pageDocumentIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitDocument + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getDisabilityCalculationDetailDocumentData();
  }

  onEditDocument(file: any): void {
    // Open form upload
    let rowData = deepClone(file);
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = DOCUMENT_LOCATION.DISABILITY;
    rowData['documentLocationRouter'] =
      `/member/disability-overview/${this.calculationType}/${this.memberId}/calculation-detail/${this.calculationBenefitId}/${this.calculationRecordId}`;
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(rowData?.fileId ?? ''),
    };
    const specificMenuData = {
      usedForMenu: USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
      documentTypeOptionList: deepClone(DISABILITY_DOCUMENT_TYPE_OPTIONS),
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        specificMenuData,
      },
    });

    editDocumentDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((objectUpload: any) => {
        if (objectUpload) {
          const request = {
            documentName: objectUpload.documentName,
            tags: objectUpload.tags,
            documentDescription: objectUpload.description,
            showOnOverview: objectUpload.showOnOverview,
            documentType: objectUpload.type,
          };

          this.calculationStore.dispatch(
            editRetirementBenefitDocumentAction({
              memberId: this.memberId,
              calculationBenefitDocumentId: rowData?.id,
              request,
            }),
          );
        }
      });
  }

  registerEditDocumentSelector(): void {
    this.calculationStore
      .pipe(select(editRetirementBenefitDocumentsSelector), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.calculationStore.dispatch(clearEditRetirementBenefitDocumentStateAction());

        if (!data) {
          return;
        }

        const responseState = data.state?.state;
        const responseAction = data.state?.action;

        if (responseState === STATE.FAIL && data.errorMsg) {
          showBanner.call(this, responseState, '', '', { customMessage: data.errorMsg });
        } else if (responseState === STATE.FAIL && !data.errorMsg) {
          showBanner.call(this, responseState, 'Disability Document', responseAction);
        } else {
          showBanner.call(this, responseState, 'Disability Document', responseAction);
          this.getDisabilityCalculationDetailDocumentData();
        }
      });
  }

  onRemoveDocument(row: any): void {
    this.removedDocumentId = row.calculationBenefitDocumentDetailId;
    const request = {
      calculationBenefitDocumentId: this.removedDocumentId,
      calculationBenefitId: this.calculationBenefitId,
      calculationType: this.calculationType,
      calculationRecordId: this.calculationRecordId,
    };
    this.calculationStore.dispatch(checkRetirementDocumentCanRemoveAction({ request }));
  }

  selectorCheckDocumentCanRemove(): void {
    this.calculationStore
      .select(checkExistCheckRetirementDocumentCanRemoveSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCheckRetirementDocumentCanRemoveStateAction());

        const canRemoveDoc = response?.payload?.isValid;

        if (canRemoveDoc) {
          this.removeDocumentEvent();
          return;
        }

        const calculationBenefitStatus = response?.payload?.calculationBenefitStatus;
        this.removeDocumentErrorMessage(calculationBenefitStatus);
      });
  }

  removeDocumentEvent(): void {
    const ALERT_MESSAGE =
      'After removed, this document cannot be used for Disability Benefit Initiation. Are you sure you want to proceed?';
    const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.Destruct,
        cancelButtonTitle: 'Cancel',
      },
    });
    confirmRemove
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const removeRequest = {
            memberId: this.memberId,
            calculationBenefitId: this.calculationBenefitId,
            calculationType: this.calculationType,
            id: this.removedDocumentId,
          };
          this.calculationStore.dispatch(removeRemoveCalculationDocumentDetailAction({ request: removeRequest }));
        }
      });
  }

  selectorRemoveDocument(): void {
    this.calculationStore
      .select(removeRemoveCalculationDocumentDetailSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response) {
          this.calculationStore.dispatch(clearRemoveRemoveCalculationDocumentDetailStateAction());

          showBanner.call(this, response?.state, 'Disability Document', response?.action);

          if (response?.state === STATE.SUCCESS) {
            this.getDisabilityCalculationDetailDocumentData();
          }
        }
      });
  }

  private removeDocumentErrorMessage(calculationBenefitStatus?: number): void {
    if (typeof calculationBenefitStatus !== 'number') {
      return;
    }

    const text =
      calculationBenefitStatus !== CalculationBenefitHistoryStatus.Initiated
        ? "This Disability Calculation Benefit's status has been modified, please refresh the page."
        : '';

    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text,
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        title: 'Error',
        hideConfirmButton: true,
      },
    });
  }

  private handleComputeAction(): void {
    this.calculationStore.dispatch(
      computeDisabilityBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );
  }

  private selectComputeDisabilityBenefitState(): void {
    this.calculationStore
      .select(computeDisabilityBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearComputeDisabilityBenefitStateAction());

        const isSuccess = res?.success;
        const isSuccessComputed = res?.payload?.isSuccess;

        const customMessage =
          isSuccess && isSuccessComputed
            ? 'Disability successfully computed.'
            : res?.error;

        showBanner.call(this, isSuccess && isSuccessComputed ? STATE.SUCCESS : STATE.FAIL, '', '', { customMessage });

        if (isSuccess && isSuccessComputed) {
          this.getCheckExceptionData(ActionButtonOnCalculationDetail[0] as unknown as ActionButtonOnCalculationDetail);
        }
      });
  }

  onClickComplete(): void {
    this.handleValidateDataChange(CalculationBenefitHistoryStatus['Pending Approval'], () => {
      this.handleCompleteAction();
    });
  }

  private handleCompleteAction(): void {
    this.calculationStore.dispatch(
      completeDisabilityBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );
  }

  private selectCompleteDisabilityBenefitState(): void {
    this.calculationStore
      .select(completeDisabilityBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearCompleteDisabilityBenefitStateAction());

        const isSuccess = res?.success;

        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'Complete', ACTION.SAVE, {
          customMessage: isSuccess
            ? 'Disability successfully completed.'
            : 'Error occurred Complete Disability. Please try again.',
        });

        if (isSuccess) {
          this.getCalculationDetailData();
        }
      });
  }

  onClickApprove(): void {
    this.handleValidateDataChange(CalculationBenefitHistoryStatus.Approved, () => {
      this.municipalityType.employerTypeControl.markAllAsTouched();
      if (this.municipalityType.isEmployerMultipleBank && !this.municipalityType.employerTypeControl.valid) {
        return;
      }
      this.handleApproveAction();
    });
  }

  private handleApproveAction(): void {
    const { employerId, employerCode, employerName } = this.municipalityType.employerTypeControl.value ?? {};
    const employerType = {
      label: this.municipalityType.employerTypeLabel,
      employerId,
      employerCode,
      employerName,
    };
    const body = this.municipalityType.isEmployerMultipleBank ? { employerType } : undefined;
    this.calculationStore.dispatch(
      approveDisabilityBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
        body,
      }),
    );
  }

  private selectApproveDisabilityBenefitState(): void {
    this.calculationStore
      .select(approveDisabilityBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearApproveDisabilityBenefitStateAction());

        const isSuccess = res?.success;

        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'Complete', ACTION.SAVE, {
          customMessage: isSuccess
            ? 'Disability successfully approved.'
            : 'Error occurred Approve Disability. Please try again.',
        });

        if (isSuccess) {
          this.getCalculationDetailData();
          this.municipalityType.getListEmployerTypes();
        }
      });
  }

  onUploadDocuments(): void {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      defaultShowOnOverview: true,
    };
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: USED_FOR_MENU.DISABILITY_CALCULATION_DETAIL,
      documentTypeOptionList: deepClone(DISABILITY_DOCUMENT_TYPE_OPTIONS),
      acceptFile: '.pdf',
      existDocumentRadioOptionList: this.existDocumentOptionList,
      benefitDocuments: this.benefitDocuments,
      detailBenefitDocument: this.disabilityBenefitDocuments,
      memberId: this.memberId,
      calculationType: this.calculationType,
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((objectUpload: any) => {
        if (!objectUpload) {
          return;
        }

        const documentList = objectUpload.documentList ?? [];
        const files = documentList.map((doc: any) => {
          return doc.id
            ? {
                id: doc.id,
                uploadDate: doc.uploadDate,
                type: doc.type,
              }
            : {
                calculationBenefitId: this.calculationBenefitId,
                uploadDate: doc.uploadDate,
                documentName: doc.documentName,
                type: doc.type,
                file: doc.file,
                tags: doc.tags,
                fileName: doc.file.name,
                documentLocationTitle: DOCUMENT_LOCATION.DISABILITY,
                documentLocationRouter: `/member/disability-overview/${this.calculationType}/${this.memberId}/calculation-detail/${this.calculationBenefitId}/${this.calculationRecordId}`,
                documentDescription: doc.decription,
                showOnOverview: doc.showOnOverview,
              };
        });

        const request: CreateRetirementBenefitDetailUploadDocumentRequest = {
          memberId: this.memberId,
          calculationType: this.calculationType,
          calculationBenefitId: this.calculationBenefitId,
          calculationRecordId: this.calculationRecordId,
          files,
        };

        this.calculationStore.dispatch(createRetirementBenefitDetailUploadDocumentAction({ request }));
      });
  }

  onDownloadFile(row: any): void {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  private selectorDownloadFile(): void {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((downloadDocument) => {
        this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());

        if (downloadDocument?.success) {
          const blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);
        }
      });
  }

  onClickEditCalculation(): void {
    const propertiesRecord = {
      beginDate: this.detailData?.benefitCalculation?.calcAsOfDate,
      endDate: this.detailData?.benefitCalculation?.benefitEndDate,
      benefitEntityId: this.detailData?.benefitEntityId ?? '',
    };
    const dialogRef = this.dialog.open(AddDisabilityComponent, {
      panelClass: 'add-new-disability',
      disableClose: true,
      autoFocus: false,
      minWidth: '1200px',
      data: {
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
        calculationRecordId: this.calculationRecordId,
        detailData: {
          numberOfCalcRecords: this.detailData?.numberOfCalculationRecordsInBenefit,
          propertiesRecord,
          editItem: this.detailData?.benefitCalculation,
          totalDays: this.detailData?.benefitCalculation?.days,
        },
        isRecalculate: true,
        calcAsOfDate: this.detailData?.benefitCalculation?.calcAsOfDate,
        benefitBeginDate: this.detailData?.benefitDetail?.beginDate,
        benefitEndDate: this.detailData?.benefitDetail?.endDate,
        isEdit: true,
        isEditBenefitCalculation: true,
        isDisabilityCalDetail: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getCalculationDetailData();
      });
  }

  /* START of EXCEPTIONS SECTION */
  onChangeSortException(sortInfo: Sort): void {
    this.sortInfoException = sortInfo;
    this.getExceptionListData();
  }

  onChangeExceptionPage(pageEvent: PageEvent): void {
    this.pageExceptionSize = pageEvent.pageSize;
    this.pageExceptionIndex = pageEvent.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityException + PAGE_SIZE_CONST,
      pageEvent.pageSize.toString(),
    );
    this.getExceptionListData();
  }

  private getExceptionListData(): void {
    const payload: Omit<GetExceptionRequest, 'sortNames' | 'sortType'> = {
      pageNumber: this.pageExceptionIndex,
      pageSize: this.pageExceptionSize,
      memberId: this.memberId,
      calculationType: this.calculationType,
      benefitTypeId: this.detailData?.benefitEntityId ?? '',
      calculationId: this.calculationRecordId ?? '',
    };

    const request = this.retirementBenefitDetailService.getGridDataRequest(
      RetirementBenefitDetailGridDataType.Exceptions,
      payload,
      this.sortInfoException,
    ) as GetExceptionRequest;
    this.calculationStore.dispatch(getExceptionListAction({ request }));
  }

  private selectExceptionListState(): void {
    this.calculationStore
      .select(getExceptionListSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isExceptionLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetExceptionListStateAction());

        this.exceptionList = (response?.payload ?? []).map((item) => {
          return {
            ...item,
            exceptionTime: DateTime.fromISO(getDateString(item.exceptionTime), { zone: 'utc' }).toJSDate().toString(),
          };
        });
        this.totalExceptionRecords = response?.total ?? 0;
      });
  }
  /* END of EXCEPTIONS SECTION */

  /* START of Check Exception */
  private getCheckExceptionData(buttonAction: ActionButtonOnCalculationDetail): void {
    const request: CheckExceptionRequest = {
      memberId: this.memberId,
      benefitEntityId: this.detailData?.benefitEntityId ?? '',
      targetId: this.calculationRecordId,
      targetType: this.calculationType,
    };
    this.calculationStore.dispatch(checkExceptionConfigurationAction({ request, buttonAction }));
  }

  private selectCheckExceptionState(): void {
    this.calculationStore
      .pipe(
        select(checkExceptionSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());

        const isSuccess = response?.success;

        if (!isSuccess) {
          showBanner.call(this, STATE.FAIL, '', '', {
            customMessage: 'Error occurred checking Exceptions. Please try again.',
          });
        }
        // No exception OR processing Computation
        if (
          isSuccess &&
          (response?.payload?.buttonAction ===
            (ActionButtonOnCalculationDetail[0] as unknown as ActionButtonOnCalculationDetail) ||
            response?.payload?.exceptionsOccur === false)
        ) {
          this.passedExceptionTrigger.next(response?.payload?.exceptionsOccur);
        }
        // Exceptions occur
        else if (isSuccess && response?.payload?.exceptionsOccur) {
          const confirmResult = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              title: BannerType.Warning,
              text: 'Please resolve exception to proceed.',
              type: ConfirmType.Warning,
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          });
          confirmResult
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.getCalculationDetailData();
            });
        }
      });
  }
  /* END of Check Exception */

  // /* START of AUDIT TRAILS SECTION */
  onChangeSortAuditTrail(sortInfo: Sort): void {
    this.sortInfoCalculationAuditTrail = sortInfo.direction ? sortInfo : { active: 'createdDate', direction: 'desc' };
    this.getCalculationAuditTrailData();
  }

  onChangeAuditTrailPage(pageEvent: PageEvent): void {
    this.pageCalculationAuditTrailSize = pageEvent.pageSize;
    this.pageCalculationAuditTrailIndex = pageEvent.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + DisabilityTableType.DisabilityAuditTrails + PAGE_SIZE_CONST,
      pageEvent.pageSize.toString(),
    );
    this.getCalculationAuditTrailData();
  }

  private getCalculationAuditTrailData(): void {
    const payload: Omit<GetCalculationAuditTrailRequest, 'sortNames' | 'sortType'> = {
      pageNumber: this.pageCalculationAuditTrailIndex,
      pageSize: this.pageCalculationAuditTrailSize,
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationId: this.calculationRecordId,
    };

    const request: GetCalculationAuditTrailRequest = this.retirementBenefitDetailService.getGridDataRequest(
      RetirementBenefitDetailGridDataType.CalculationAuditTrails,
      payload,
      this.sortInfoCalculationAuditTrail,
    );
    this.calculationStore.dispatch(getCalculationAuditTrailAction({ request }));
  }

  private selectCalculationAuditTrailState(): void {
    this.calculationStore
      .select(getCalculationAuditTrailSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isCalculationAuditTrailLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetCalculationAuditTrailStateAction());
        this.calculationAuditTrails = (response?.payload ?? []).map((item) => {
          return {
            ...item,
            createdDate: getDateString(item.createdDate) ?? '',
          };
        });
        this.totalCalculationAuditTrailRecords = response?.total ?? 0;
      });
  }
  // /* END of AUDIT TRAILS SECTION */

  private getDisabilityBenefitDetailDocumentData(): void {
    this.calculationStore.dispatch(
      getRetirementBenefitDocumentsAction({
        request: {},
        memberId: this.memberId,
        calculationType: this.calculationType,
        calculationBenefitId: this.calculationBenefitId,
      }),
    );
  }

  private registerGetDisabilityBenefitDetailDocumentData(): void {
    this.calculationStore
      .select(getRetirementBenefitDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (!retirementBenefitDocument?.isLoading && retirementBenefitDocument?.success) {
          this.benefitDocuments = retirementBenefitDocument?.payload ?? [];
        }
      });
  }

  private validateExistDocumentNameExist(documentId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control?.value?.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.retirementBenefitDialogService
              .checkExits({
                memberId: this.memberId || '',
                name: control.value.toString(),
                fileId: documentId,
                calculationType: this.calculationType,
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  private uploadDocumentSelector(): void {
    this.calculationStore
      .select(createRetirementBenefitDetailUploadDocumentSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((retirementBenefitDocument) => {
        this.calculationStore.dispatch(clearCreateRetirementBenefitDetailUploadDocumentStateAction());

        const isSuccess = retirementBenefitDocument?.success;

        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'Document', ACTION.UPLOAD);

        if (isSuccess) {
          this.getDisabilityCalculationDetailDocumentData();
        }
      });
  }
}
